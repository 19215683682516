import React, { useState, useEffect, useContext } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import MintOverlay from './MintOverlay';
import '../css/Navigation.css'; // import our custom styling
import { initGlobalSignaler } from '../services/globalSignaler';
import {ConfigContext} from '../config';
import { AuthContext } from '../context/AuthProvider';
import NotificationsModal from './NotificationsModal';
import OverlayConfirm from './OverlayConfirm';

// The Navigation component links to routes like "/conversations", "/transactions", and "/profile".
// These pages then call the corresponding walletsv_api endpoints (for messaging, history, profile info, etc.).

const Navigation = () => {
  const config = useContext(ConfigContext);
  const { wallet, logout, walletSwitch, updateWalletSwitch, switchWallet } = useContext(AuthContext);
  const [showPostOverlay, setShowPostOverlay] = useState(false);
  const [mintParams, setMintParams] = useState({});
  const [mintOutcome, setMintOutcome] = useState(null);
  const [unreadConversations, setUnreadConversations] = useState([]);
  const [showNotificationsModal, setShowNotificationsModal] = useState(false);
  // NEW: Profiles modal state (moved from Profile.js)
  const [showProfilesModal, setShowProfilesModal] = useState(false);
  const [pendingWallet, setPendingWallet] = useState(null);
  const [showSwitchConfirm, setShowSwitchConfirm] = useState(false);
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
  
  const navigate = useNavigate();

  useEffect(() => {
    const handleOpenPostModal = () => {
      // Trigger the Post overlay logic. For example:
      setShowPostOverlay(true);
    };

    window.addEventListener('openPostModal', handleOpenPostModal);
    return () => {
      window.removeEventListener('openPostModal', handleOpenPostModal);
    };
  }, []);

  // NEW: Manage unread conversations at global level.
  useEffect(() => {
    if (!wallet) return;
    const globalSignaler = initGlobalSignaler(wallet);
    
    const updateUnread = (e) => {
      setUnreadConversations(e.detail); // e.detail is an array of convo IDs.
    };
    globalSignaler.addEventListener('unreadUpdate', updateUnread);
    
    // Initial update.
    setUnreadConversations(Array.from(globalSignaler.unreadConvs));
    
    // NEW: Poll global unreadConvs every 5 seconds
    const intervalId = setInterval(() => {
      setUnreadConversations(Array.from(globalSignaler.unreadConvs));
    }, 3000);
    
    return () => {
      globalSignaler.removeEventListener('unreadUpdate', updateUnread);
      clearInterval(intervalId);
    };
  }, [wallet]);


  const onLogout = () => {
    setShowLogoutConfirm(true);
  };

  const handleLogoutConfirmComplete = ({ status }) => {
    setShowLogoutConfirm(false);
    if (status && wallet) {
      const globalSignaler = initGlobalSignaler(wallet);
      globalSignaler.removeAllChats();
      // Delay logout to avoid concurrent rendering errors
      setTimeout(() => {
        logout();
        navigate('/auth');
      }, 0);
    }
  };
  

  // NEW: Handle profile change (from Profile.js)
  const handleChangeProfile = (type, value) => {
    setShowProfilesModal(false);
    if (type === "logout") {
      onLogout();
    } else if (type === "switch") {
      window.location.href = '/auth'; // replaced navigate('/auth')
    } else if (type === "select") {
      setPendingWallet(value);
      setShowSwitchConfirm(true);
    }
  };



  return (
    <nav className="navigation-bar">
      <div className="nav-container">
        <NavLink className="nav-brand" to="/">
          <img src="/logo.png" alt="Logo" style={{ marginRight: '10px' }} width={33} />
          {config.LOGO_NAME}
        </NavLink>
        <div className="nav-links">
          {/* Existing Post button */}
          <button 
            className="nav-item-button" 
            onClick={() => {
              if (wallet) {
                setShowPostOverlay(true);
                setMintOutcome(null);
              } else {
                window.location.href = '/auth'; // replaced navigate('/auth')
              }
            }}>
            <i className="fa fa-plus-square"></i>
          </button>
          
          {/* Removed menus for Developers, Apps, and Admin; these are now accessible via the footer and Developers page */}
          <NavLink className="nav-item" to={wallet ? "/conversations" : "/auth"}>
            <i className="fa fa-envelope"></i>
            {unreadConversations.length > 0 && <span className="notification-dot"></span>}
          </NavLink>
          <button onClick={() => setShowNotificationsModal(true)} className="nav-item-button">
            <i className="fa fa-bell"></i>
          </button>
        </div>
        <div className="nav-auth">
          {wallet ? (
            // Replace the previous Profile link with a Profiles button
            <>
            <button className="nav-item-button" onClick={() => navigate('/profile')}>
              <i className="fa fa-user"></i>
            </button>
            <button className="nav-item-button" onClick={() => setShowProfilesModal(true)}>
              <i className="fa fa-exchange"></i>
            </button>
            </>
            
          ) : (
            <button onClick={() => { window.location.href = '/auth'; }} className="btn btn-warning p-0 px-2 ">
             <svg width={25} focusable="false" viewBox="0 0 24 24" aria-hidden="true" style={{ margin:'0.1rem' }}>
                <path d="M17.81 4.47c-.08 0-.16-.02-.23-.06C15.66 3.42 14 3 12.01 3c-1.98 0-3.86.47-5.57 1.41-.24.13-.54.04-.68-.2-.13-.24-.04-.55.2-.68C7.82 2.52 9.86 2 12.01 2c2.13 0 3.99.47 6.03 1.52.25.13.34.43.21.67-.09.18-.26.28-.44.28zM3.5 9.72c-.1 0-.2-.03-.29-.09-.23-.16-.28-.47-.12-.7.99-1.4 2.25-2.5 3.75-3.27C9.98 4.04 14 4.03 17.15 5.65c1.5.77 2.76 1.86 3.75 3.25.16.22.11.54-.12.7-.23.16-.54.11-.7-.12-.9-1.26-2.04-2.25-3.39-2.94-2.87-1.47-6.54-1.47-9.4.01-1.36.7-2.5 1.7-3.4 2.96-.08.14-.23.21-.39.21zm6.25 12.07c-.13 0-.26-.05-.35-.15-.87-.87-1.34-1.43-2.01-2.64-.69-1.23-1.05-2.73-1.05-4.34 0-2.97 2.54-5.39 5.66-5.39s5.66 2.42 5.66 5.39c0 .28-.22.5-.5.5s-.5-.22-.5-.5c0-2.42-2.09-4.39-4.66-4.39s-4.66 1.97-4.66 4.39c0 1.44.32 2.77.93 3.85.64 1.15 1.08 1.64 1.85 2.42.19.2.19.51 0 .71-.11.1-.24.15-.37.15zm7.17-1.85c-1.19 0-2.24-.3-3.1-.89-1.49-1.01-2.38-2.65-2.38-4.39 0-.28.22-.5.5-.5s.5.22.5.5c0 1.41.72 2.74 1.94 3.56.71.48 1.54.71 2.54.71.24 0 .64-.03 1.04-.1.27-.05.53.13.58.41.05.27-.13.53-.41.58-.57.11-1.07.12-1.21.12zM14.91 22c-.04 0-.09-.01-.13-.02-1.59-.44-2.63-1.03-3.72-2.1-1.4-1.39-2.17-3.24-2.17-5.22 0-1.62 1.38-2.94 3.08-2.94s3.08 1.32 3.08 2.94c0 1.07.93 1.94 2.08 1.94s2.08-.87 2.08-1.94c0-3.77-3.25-6.83-7.25-6.83-2.84 0-5.44 1.58-6.61 4.03-.39.81-.59 1.76-.59 2.8 0 .78.07 2.01.67 3.61.1.26-.03.55-.29.64-.26.1-.55-.04-.64-.29-.49-1.31-.73-2.61-.73-3.96 0-1.2.23-2.29.68-3.24 1.33-2.79 4.28-4.6 7.51-4.6 4.55 0 8.25 3.51 8.25 7.83 0 1.62-1.38 2.94-3.08 2.94s-3.08-1.32-3.08-2.94c0-1.07-.93-1.94-2.08-1.94s-2.08.87-2.08 1.94c0 1.71.66 3.31 1.87 4.51.95.94 1.86 1.46 3.27 1.85.27.07.42.35.35.61-.05.23-.26.38-.47.38z"></path>
              </svg>
               
            </button>
          )}
        </div>
        
      </div>
      {showNotificationsModal && (
        <NotificationsModal 
          wallet={wallet} 
          onClose={() => setShowNotificationsModal(false)} 
        />
      )}
      {/* NEW: Profiles Modal Overlay */}
      {showProfilesModal && (
        <div 
          className="modal-overlay" 
          onClick={() => setShowProfilesModal(false)}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.6)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
          }}
        >
          <div 
            className="modal-content"
            onClick={e => e.stopPropagation()}
            style={{
              backgroundColor: '#fff',
              padding: '20px',
              borderRadius: '4px',
              maxWidth: '600px',
              width: '90%',
            }}
          >
            <div className="modal-header" style={{ fontWeight: 'bold', fontSize: '1.25rem', marginBottom: '10px', textAlign: 'center' }}>
              Select Profile
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
              {walletSwitch.map(w => (
                <button 
                  key={w.address}
                  className={`btn ${w.address === wallet.address ? "btn-info" : "btn-outline-secondary"} border w-100`}
                  onClick={() => {
                    if(w.address === wallet.address){
                      window.location.href = '/profile'; // replaced navigate('/profile')
                    } else {
                      handleChangeProfile("select", w);
                    }
                  }}
                >
                  {w.username ? '@' + w.username : w.address}
                </button>
              ))}
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '1rem' }}>
              <button 
                className="btn btn-outline-primary"
                onClick={() => handleChangeProfile("switch")}
              >
                Add Profile
              </button>
              <button 
                className="btn btn-outline-danger"
                onClick={() => handleChangeProfile("logout")}
              >
                Logout All
              </button>
            </div>
          </div>
        </div>
      )}
      <MintOverlay
        show={showPostOverlay}
        wallet={wallet} // removed JSON.parse because wallet is already parsed
        mintParams={mintParams}
        outcome={mintOutcome}
        onTransactionComplete={(txResult) => {
          setMintOutcome(txResult);
        }}
        onCopyOutcome={() => {}}
        onResetOutcome={() => {
          setMintOutcome(null);
        }}
        onClose={() => {
          setShowPostOverlay(false);
          setMintOutcome(null);
        }}
        onUpdateMintParams={(updatedParams) => setMintParams(updatedParams)}
      />

      {showLogoutConfirm && (
        <OverlayConfirm 
          confirmation={{
            messages: [
              "Have you backed up your private key? We cannot recover your account.",
              "Are you absolutely sure you want to logout? P2P chats will be cleared and lost.",
              "There is no go back. Last chance. Have you backed up your wallet?"
            ],
            requiresInput: false
          }}
          optionsMsg={{ confirm: "Yes, Logout", cancel: "No, Stay" }}
          onComplete={handleLogoutConfirmComplete}
        />
      )}

      {showSwitchConfirm && (
        <OverlayConfirm 
          confirmation={{
            messages: [
              "Switching profiles will clear your current P2P conversations and messages. Are you sure you want to Switch?"
            ],
            requiresInput: false
          }}
          optionsMsg={{ confirm: "Yes, Switch", cancel: "Cancel" }}
          onComplete={(result) => {
            setShowSwitchConfirm(false);
            if (result.status && pendingWallet) {
              const globalSignaler = initGlobalSignaler(wallet);
              globalSignaler.removeAllChats();
              switchWallet(pendingWallet);
              setPendingWallet(null);
              navigate('/profile');
            } else {
              setPendingWallet(null);
            }
          }}
        />
      )}
    </nav>
  );
};

export default Navigation;