import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import NFTCard from '../components/NFTCard';
import { getPostByContractTx, getPostComments } from '../services/apiOutCalls';
import { AuthContext } from '../context/AuthProvider';
import EmbeddedPost from '../components/EmbeddedPost'; // NEW

const PostDetailsPage = () => {
  const { postid } = useParams();
  const { wallet } = useContext(AuthContext);
  const [parentPost, setParentPost] = useState(null);
  const [comments, setComments] = useState([]);
  const [totalComments, setTotalComments] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingParent, setLoadingParent] = useState(true);
  const [loadingComments, setLoadingComments] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // Remove wallet retrieval from localStorage
  useEffect(() => {
    if (!wallet) {
      navigate('/');
    }
    console.log('Wallet:', parentPost);
  }, [wallet, navigate]);

  // Fetch parent post using getPostByContractTx
  useEffect(() => {
    if (!wallet) return;
    const fetchParent = async () => {
      try {
        const result = await getPostByContractTx(wallet, postid);
        if (result.success) {
          setParentPost(result.post);
        } else {
          setError(result.error || "Failed to fetch parent post");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoadingParent(false);
      }
    };
    fetchParent();
  }, [postid, wallet]);

  // Fetch paginated comments using getPostComments
  useEffect(() => {
    if (!wallet) return;
    const fetchComments = async () => {
      try {
        setLoadingComments(true);
        const result = await getPostComments(wallet, postid, currentPage, 10);
        if (result.success) {
          setComments(result.comments);
          setTotalComments(result.total);
        } else {
          setError(result.error || "Failed to fetch comments");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoadingComments(false);
      }
    };
    fetchComments();
  }, [postid, wallet, currentPage]);

  if (loadingParent || loadingComments) return <div className="spinner">Loading comments...</div>; // replaced loader
  if (error) return <div className="alert alert-danger">Error: {error}</div>;

  const totalPages = Math.ceil(totalComments / 10);

  return (
    <div className="post-details-page container p-3">
      {/* Parent Post Card */}
      {parentPost && (
        <div className="mb-4">
          {parentPost.properties && parentPost.properties.reaction && parentPost.properties.reaction.type === 'replyTo' ? (
            <div style={{ marginBottom: '20px', border: '2px solid #ccc' }}>
              <EmbeddedPost
                parentEmbed={true}
                wallet={wallet}
                meta={parentPost.properties.reaction}
              />
              <EmbeddedPost
                isReply={true}
                wallet={wallet}
                meta={parentPost}
                post={parentPost.properties.reaction}
              />
            </div>
          ) : (
            <NFTCard
              wallet={wallet}
              post={parentPost}
              embedded={false}
              isReply={false}
              ownProfile={false}
            />
          )}
        </div>
      )}
      {/* Comments */}
      {comments.length > 0 && <p className="mb-1"><strong>Comments</strong></p>}
      <div className="comments-section">
        {comments.length > 0 ? (
          comments.map(comment => (
            <div key={comment.contractTx} className="mb-3">
              <NFTCard 
                wallet={wallet}
                post={comment}
                embedded={true}
                isReply={true}
                ownProfile={false}
              />
            </div>
          ))
        ) : (
          <div>No comments found.</div>
        )}
      </div>
      {totalPages > 1 && (
        <div className="pagination-controls">
          <button 
            disabled={currentPage === 1}
            onClick={() => setCurrentPage(prev => prev - 1)}
          >
            Previous
          </button>
          <span> Page {currentPage} of {totalPages} </span>
          <button 
            disabled={currentPage === totalPages}
            onClick={() => setCurrentPage(prev => prev + 1)}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default PostDetailsPage;
