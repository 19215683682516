import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext(null);

/*
  Production-ready encryption using SubtleCrypto with AES-GCM and random IV per encryption.
  Note: In production you should derive the key from a secure source or user passphrase.
  Here we use a fixed raw key for demonstration purposes only.
*/

// Configuration for encryption.
const ALGO_NAME = "AES-GCM";
const KEY_LENGTH = 256; 

const rawKey = new TextEncoder().encode("*Dn>(j|/Dx}73T}Q*x<aZh+WG_@![|ZK");

// Import the fixed key. In production, use a secure derived key.
let cryptoKeyPromise = window.crypto.subtle.importKey(
  "raw",
  rawKey,
  { name: ALGO_NAME },
  false,
  ["encrypt", "decrypt"]
);

/*
  encryptData: Encrypts the JSON stringified data using AES-GCM.
  A random 12-byte IV is generated per encryption.
  Returns a string of the form: base64(iv):base64(ciphertext)
*/
async function encryptData(data) {
  const key = await cryptoKeyPromise;
  const iv = window.crypto.getRandomValues(new Uint8Array(12));
  const encoded = new TextEncoder().encode(JSON.stringify(data));
  const ciphertextBuffer = await window.crypto.subtle.encrypt(
    { name: ALGO_NAME, iv },
    key,
    encoded
  );
  // Convert both IV and ciphertext to Base64
  const ivB64 = btoa(String.fromCharCode(...iv));
  const cipherB64 = btoa(String.fromCharCode(...new Uint8Array(ciphertextBuffer)));
  return ivB64 + ":" + cipherB64;
}

/*
  decryptData: Expects an input string formatted as base64(iv):base64(ciphertext), splits it,
  decodes and decrypts using AES-GCM.
*/
async function decryptData(encryptedStr) {
  const key = await cryptoKeyPromise;
  const parts = encryptedStr.split(":");
  if (parts.length !== 2) throw new Error("Malformed encrypted data");
  const iv = Uint8Array.from(atob(parts[0]), ch => ch.charCodeAt(0));
  const cipherBytes = Uint8Array.from(atob(parts[1]), ch => ch.charCodeAt(0));
  const plainBuffer = await window.crypto.subtle.decrypt(
    { name: ALGO_NAME, iv },
    key,
    cipherBytes
  );
  const decoded = new TextDecoder().decode(plainBuffer);
  return JSON.parse(decoded);
}

export const AuthProvider = ({ children }) => {
  // Instead of storing plain wallet data, we store the encrypted string.
  const [wallet, setWallet] = useState(null);
  
  const [walletSwitch, setWalletSwitch] = useState([]);

  useEffect(() => {
    const storedWallet = localStorage.getItem('wallet');
    if (storedWallet) {
      let isPlain = false;
      try {
        JSON.parse(storedWallet);
        isPlain = true;
      } catch (e) {
        isPlain = false;
      }
      if (!isPlain) {
        // Encrypted format detected
        decryptData(storedWallet)
          .then(walletData => setWallet(walletData))
          .catch(err => console.error("Decryption error (wallet):", err));
      } else {
        // Plain format detected
        try {
          const walletData = JSON.parse(storedWallet);
          setWallet(walletData);
          // Upgrade and re-save in encrypted format
          encryptData(walletData)
            .then(enc => localStorage.setItem('wallet', enc))
            .catch(err => console.error("Encryption error (wallet):", err));
        } catch (e) {
          console.error("Failed to parse plain wallet:", e);
        }
      }
    }
  }, []);

  useEffect(() => {
    const storedSwitch = localStorage.getItem("wallet_switch");
    if (storedSwitch) {
      let isPlain = false;
      try {
        JSON.parse(storedSwitch);
        isPlain = true;
      } catch (e) {
        isPlain = false;
      }
      if (!isPlain) {
        // Encrypted format detected
        decryptData(storedSwitch)
          .then(data => setWalletSwitch(data))
          .catch(err => {
            console.error("Decryption error (wallet_switch):", err);
            setWalletSwitch([]);
          });
      } else {
        // Plain format detected
        try {
          const data = JSON.parse(storedSwitch);
          setWalletSwitch(data);
          // Upgrade and re-save in encrypted format
          encryptData(data)
            .then(enc => localStorage.setItem("wallet_switch", enc))
            .catch(err => console.error("Encryption error (wallet_switch):", err));
        } catch (e) {
          console.error("Failed to parse plain wallet_switch:", e);
          setWalletSwitch([]);
        }
      }
    }
  }, []);

  const saveWallet = async (walletData) => {
    console.log('Saving wallet:', walletData);
    setWallet(walletData);
    // Encrypt before storage.
    const encrypted = await encryptData(walletData);
    localStorage.setItem('wallet', encrypted);
    addWalletSwitch(walletData);
  };

  // Updated addWalletSwitch without an async callback:
  const addWalletSwitch = (newWallet) => {
    setWalletSwitch(prev => {
      const current = Array.isArray(prev) ? prev : [];
      const exists = current.some(w => w.address === newWallet.address);
      const updated = exists ? current : [...current, newWallet];
      encryptData(updated)
        .then(enc => localStorage.setItem("wallet_switch", enc))
        .catch(err => console.error("Encryption error (wallet_switch add):", err));
      return updated;
    });
  };

  // Updated updateWalletSwitch without an async callback:
  const updateWalletSwitch = (updatedWallet) => {
    setWalletSwitch(prev => {
      const current = Array.isArray(prev) ? prev : [];
      const updatedList = current.map(w => w.address === updatedWallet.address ? updatedWallet : w);
      encryptData(updatedList)
        .then(enc => localStorage.setItem("wallet_switch", enc))
        .catch(err => console.error("Encryption error (wallet_switch update):", err));
      return updatedList;
    });
  };

  const switchWallet = (newWallet) => {
    saveWallet(newWallet);
  };

  const logout = () => {
    setWallet(null);
    setWalletSwitch([]);
    localStorage.removeItem('wallet');
    localStorage.removeItem('wallet_switch');
  };

  return (
    <AuthContext.Provider value={{ wallet, saveWallet, switchWallet, logout, walletSwitch, setWallet, updateWalletSwitch }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
