import {useContext} from 'react';
import { ec as EC } from 'elliptic';
import bs58check from 'bs58check';
import SHA256 from 'crypto-js/sha256';
import { PrivateKey, P2PKH, Hash } from '@bsv/sdk';
import axios from 'axios';
import {config} from '../config';

const ec = new EC('secp256k1');

// Helper: Convert WIF to a private key Buffer
function wifToPrivateKey(privateKeyWIF) {
  return PrivateKey.fromWif(privateKeyWIF.trim());
}

// Helper: sign the payload using the provided WIF private key
// Note: the payload body now contains only the data.
export function signPayload(privateKeyWIF, payload) {
  const privateKeyBuffer = wifToPrivateKey(privateKeyWIF);
  const keyPair = ec.keyFromPrivate(privateKeyBuffer);
  const publicKeyHex = keyPair.getPublic(true, 'hex');
  
  // Build canonical payload with only data
  const canonicalPayloadStr = JSON.stringify({ data: payload.data });
  
  // Compute SHA256 hash (hex string)
  const hashHex = Buffer.from(SHA256(canonicalPayloadStr).toString());
  const signature = keyPair.sign(hashHex).toDER('hex');
  
  return { publicKey: publicKeyHex, signature, canonicalPayloadStr };
}

// NEW: Helper functions to perform signed requests
async function signedRequest(wallet, method, url, dataObj, extraHeaders = {}) {
	// Build payload using dataObj
	const payload = { data: dataObj };
	const { publicKey, signature, canonicalPayloadStr } = signPayload(wallet.privateKey, payload);
	const headers = {
		"Content-Type": "application/json",
		"x-signature": signature,
		"x-pubkey": publicKey,
		...extraHeaders
	};
	const options = { method, headers };
  const urlfull = `${config.API_URL}${url}`;

	if(method !== "GET") {
		options.body = canonicalPayloadStr;
	}
	const response = await fetch(urlfull, options);
	return response.json();
}

// Replace the existing signedGet with this updated version:
async function signedGet(wallet, endpoint, dataObj = {}, extraHeaders = {}) {
  const payload = { data: dataObj };
  const { publicKey, signature } = signPayload(wallet.privateKey, payload);
  const query = encodeURIComponent(JSON.stringify(payload.data));
  const url = `${config.API_URL}${endpoint}?data=${query}`;
  const headers = {
    "Content-Type": "application/json",
    "x-signature": signature,
    "x-pubkey": publicKey,
    ...extraHeaders,
  };
  const response = await fetch(url, { method: "GET", headers });
  return response.json();
}

// Refactor createPost:
export async function createPost(wallet, postData) {
	const payload = { action: "create_post", post: postData };
	return signedRequest({ privateKey: wallet.privateKey }, "POST", `/post/create`, payload);
}

// Refactor deletePost:
export async function deletePost(wallet, contractTx) {
	const payload = { action: "delete_post", contractTx };
	return signedRequest(wallet, "DELETE", `/post`, payload);
}

// Refactor searchPosts:
export async function searchPosts(wallet, searchQuery, page = 1, limit = 10) {
	const payload = { action: "search", ...searchQuery, options: { page, limit } };
	return signedRequest(wallet, "POST", `/feed/search`, payload);
}

// Refactor createReaction:
export const createReaction = async (wallet, reactionData) => {
	const payload = { action: "emotion", reaction: reactionData };
	return signedRequest({ privateKey: wallet.privateKey }, "POST", `/reaction/create`, payload);
};

// Refactor getReactionCount:
export async function getReactionCount(wallet,contractTx) {
	const payload = { action: "countreaction", contractTx };
	return signedRequest({ privateKey: wallet.privateKey }, "POST", `/reaction/count`, payload)
		.then(result => result.totals);
}

// Refactor dynamicApiCall:
export async function dynamicApiCall(wallet, callData, extraHeaders = {}) {
	return signedRequest(wallet, "POST", `/data/api`, callData, extraHeaders);
}

// Refactor broadcastTransactions:
export const broadcastTransactions = async (wallet, raws, params) => {
	const rawArray = typeof raws === 'string' ? [raws] : raws;
	return signedRequest(wallet, "POST", `/data/api`, { action: "broadcastTransactions", raws: rawArray, params })
		.then(result => result.data);
};

// Refactor broadcastBigTransaction:
export const broadcastBigTransaction = async (wallet, raws, params) => {
	return signedRequest(wallet, "POST", `/data/api`, { action: "broadcastBigTransaction", txhex: raws, params })
		.then(result => result.data);
};

// Refactor transferProcessing:
export async function transferProcessing(transferHex, post, newOwner, wallet) {
	const payload = { action: "transfer_processing", transferHex, post, newOwner };
	return signedRequest(wallet, "POST", `/transfer/processing`, payload)
		.catch(error => ({ success: false, error: error.message }));
}

// Refactor redeemProcessing:
export async function redeemProcessing(redeemHex, post, wallet) {
	const payload = { action: "redeem_processing", redeemHex, post };
	return signedRequest(wallet, "POST", `/redeem/processing`, payload)
		.catch(error => ({ success: false, error: error.message }));
}

// Refactor trendingSearch:
export async function trendingSearch(wallet, trendingParams) {
	return signedRequest(wallet, "POST", `/trending/search`, trendingParams)
		.then(result => result.posts);
}

// Refactor leaderboardSearch:
export async function leaderboardSearch(wallet, leaderboardParams) {
	return signedRequest(wallet, "POST", `/leaderboard/search`, leaderboardParams)
		.then(result => result.leaderboard);
}

// Refactor getPostByContractTx:
export async function getPostByContractTx(wallet, contractTx) {
	const payload = { action: "get_post", contractTx };
	return signedGet(wallet, `/post/find`, payload);
}

// Refactor getPostComments:
export const getPostComments = async (wallet, postId, page = 1, limit = 10) => {
	const payload = { action: "get_post_comments", postId, page, limit };
	return signedGet(wallet, `/post/comments`, payload);
};

// Refactor linkUsername:
export const linkUsername = async (wallet, username) => {
	return signedRequest(wallet, "POST", `/user/link-username`, { username });
};

// Refactor unlinkUsername:
export const unlinkUsername = async (wallet) => {
	return signedRequest(wallet, "POST", `/user/unlink-username`, {});
};

// Replace the existing getUsername with the new lookupUser export.
export const lookupUser = async (wallet, lookupParam = {}) => {
  // lookupParam can include: { lookUserName: "desiredName" }
  return signedGet(wallet, `/user/lookup-user`, lookupParam);
};

export const lookupPubkeyhash = (wallet, username) => {
	return signedRequest(wallet, "POST", "/user/lookup", { username });
  };
  
  // New helper: Bulk lookup usernames by an array of pubkeyhashes.
  export const lookupUsersBulk = (wallet, pubkeyhashes) => {
	  // Assuming backend expects a POST with { pubkeyhashes : [...] }
	  return signedRequest(wallet, "POST", "/user/bulk/lookup", { pubkeyhashes });
  };

// Add new helper: get user comments by pubkeyhash
export const getUserComments = async (wallet, pubkeyhash, page = 1, limit = 10) => {
	const payload = { pubkeyhash, page, limit };
	return signedGet(wallet, '/user/comments', payload);
};

// Add below existing exports:
export async function getNotifications(wallet, params = {}) {
  // params can include: { days: number, fromDate: string }
  return signedGet(wallet, '/api/updates', params);
}
