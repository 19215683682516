// The Visitor page handles user profiles, NFT holdings and tip transfers.
// It interacts with backend NFT endpoints (dynamicRequest calls) and tip processing 
// (which may in turn use endpoints such as POST "/redeem/processing").

import React, { useState, useEffect, useContext } from 'react';
import { useParams, NavLink } from 'react-router-dom';
import { QRCodeCanvas } from 'qrcode.react';
import Stas789NFTs from '../components/Stas789NFTs'; // adjust path if needed
import BSVTransferForm from '../components/BSVTransferForm';
import ReceiveQRCodeOverlay from '../components/ReceiveQRCodeOverlay'; // added
import { AuthContext } from '../context/AuthProvider'; // added
import { lookupUser } from '../services/apiOutCalls';
import { getPKHashFromAddress } from '../utils/hash160';
import { followUser, checkFollowStatus } from '../services/devApiService'; // Updated import
import { getUserComments } from '../services/apiOutCalls'; // NEW
import { useGlobalAlert } from '../components/GlobalAlert'; // NEW
import EmbeddedPost from '../components/EmbeddedPost'; // NEW
import { ConfigContext } from '../config';
import '../css/Visitor.css'; // new CSS for visitor page

const Visitor = () => {
  const { showAlert } = useGlobalAlert(); // add this line
  
  const config = useContext(ConfigContext);
  const { profile } = useParams();
  const { wallet } = useContext(AuthContext); // use wallet from AuthContext
  const [nftHoldings, setNftHoldings] = useState([]);
  const [loadingNFTs, setLoadingNFTs] = useState(true);
  const [showQR, setShowQR] = useState(false);
  const [showTipOverlay, setShowTipOverlay] = useState(false);
  const [tranferParams, setTranferParams] = useState({});
  const [visitorUsername, setVisitorUsername] = useState(null);
  const [followed, setFollowed] = useState(false);
  // NEW: State for tab control and comments
  const [activeTab, setActiveTab] = useState("nfts");
  const [userComments, setUserComments] = useState([]);
  const [commentsLoading, setCommentsLoading] = useState(false);

  // Add handleThumbnailClick to open QR overlay
  const handleThumbnailClick = () => {
    setShowQR(true);
  };

  useEffect(() => {
    // Remove localStorage wallet retrieval
    // Simulate API call to fetch NFT holdings for the profile address
    setLoadingNFTs(true);
    setTimeout(() => {
      // Replace with real API call to fetch NFT tokens for the address "profile"
      setNftHoldings([]); 
      setLoadingNFTs(false);
    }, 1000);
  }, [profile]);

  useEffect(() => {
    // NEW: Fetch visitor username by converting the BSV address to pubkeyhash and looking up the username.
    async function fetchVisitorUsername() {
      try {
        const pubkeyhash = getPKHashFromAddress(profile);
        console.log("Fetching visitor username for pubkeyhash", pubkeyhash);
        const result = await lookupUser(wallet, { pubkeyhash });
        if (result.success && result.user && result.user.username) {
          setVisitorUsername(result.user.username);
        }
      } catch (err) {
        console.error("Error fetching visitor username", err);
      }
    }
    fetchVisitorUsername();
  }, [profile, wallet]);

  useEffect(() => {
    // Check if the user is already following the profile
    async function checkFollowStatusForProfile() {
      if (!wallet || !profile) return;
      try {
        const pubkeyhash = getPKHashFromAddress(profile);
        const res = await checkFollowStatus(wallet, pubkeyhash);
        if (res.success) {
          setFollowed(res.isFollowing);
        }
      } catch (err) {
        console.error("Error checking follow status:", err);
      }
    }
    checkFollowStatusForProfile();
  }, [wallet, profile]);

  // NEW: Fetch user comments when comments tab is active
  useEffect(() => {
    const fetchComments = async () => {
      if (activeTab === "comments" && wallet && profile) {
        setCommentsLoading(true);
        try {
          const pkhash = getPKHashFromAddress(profile);
          const result = await getUserComments(wallet, pkhash);
          if (result.success) {
            setUserComments(result.comments);
          } else {
            setUserComments([]);
          }
        } catch (err) {
          console.error("Error fetching user comments:", err);
          setUserComments([]);
        } finally {
          setCommentsLoading(false);
        }
      }
    };
    fetchComments();
  }, [activeTab, wallet, profile]);

  // Handler for tip functionality.
  const onTipClick = (fiatvalue) => {
    if (config.FX_BSV === 0) {
      showAlert("We couldn't fetch current $BSV rate. If the problem persists please contact support.", "danger", "Error");
      return;
    }
    // Use config.FX_BSV from config instead of hardcoded 36.
    // Generate a P2PKH address from the tokenId hex.
    const tipAddress = profile;
    setTranferParams({
      recipients: [
        {
          address: tipAddress,
          value: fiatvalue / config.FX_BSV * 100000000,
          fiatvalue,
        }
      ]
    });
    setShowTipOverlay(true);
  };
  
  // When transaction completes, hide overlays and display outcome.
  const handleTransactionComplete = (txResult) => {
    // setOutcome(txResult);
    setShowTipOverlay(false);
  };

  const handleFollow = async () => {
    if (!wallet || !profile) return;
    const pubkeyhash = getPKHashFromAddress(profile);
    const res = await followUser(wallet, pubkeyhash);
    if (res.success) {
      setFollowed(true);
    }
  };

  return (
    <div className="visitor-profile p-3">
      <div className="visitor-header card p-3 mb-3">
        {visitorUsername ? (
          <button className="btn btn-light btn-sm">
            @{visitorUsername}
          </button>
        ) : (
          <span>
            <strong>Profile:</strong> {profile ? profile.substring(0, 6) + "...." + profile.substring(profile.length - 6) : ""}
          </span>
        )}
        <div className="visitor-info d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <div className="visitor-qr" onClick={handleThumbnailClick} style={{ cursor: 'pointer', textAlign: 'center' }}>
              <QRCodeCanvas value={"Click me to view Address"} size={40} />
            </div>
            
            {wallet && wallet.address !== profile && ( // Hide follow button if profile matches wallet address
              <button className="btn btn-outline-dark btn-sm ms-2" disabled={followed} onClick={handleFollow}>
                {followed ? 'Followed' : 'Follow'}
              </button>
            )}
          </div>
          {wallet && wallet.address !== profile ? (
            <div className="visitor-actions">
              <NavLink to={`/message/${profile}`} className="btn btn-outline-dark btn-sm">
                Message
              </NavLink>

              <button 
                className="btn btn-info btn-sm" 
                style={{ marginLeft: '10px' }} // added margin for gap
                onClick={() => {
                  onTipClick(5);
                  setShowTipOverlay(true);
                }}>
                Tip $5
              </button>
            </div>
          ):(
            <div className="visitor-actions">
              <button className="btn btn-outline-dark btn-sm me-2" disabled>
                Message
              </button>
              <button className="btn btn-info btn-sm" disabled>
                Tip $5
              </button>
            </div>
          )}
        </div>
        {showQR && (
          <ReceiveQRCodeOverlay 
            walletAddress={profile} 
            onClose={() => setShowQR(false)} 
          />
        )}
      </div>
      {/* NEW: Tabs for NFT Holdings and User Comments */}
      <div className="tabs mb-3">
        <button 
          className={`btn ${activeTab === "nfts" ? "btn-primary" : "btn-outline-primary"} me-2`}
          onClick={() => setActiveTab("nfts")}
        >
          NFT Posts
        </button>
        <button 
          className={`btn ${activeTab === "comments" ? "btn-primary" : "btn-outline-primary"}`}
          onClick={() => setActiveTab("comments")}
        >
          NFT Comments
        </button>
      </div>
      {activeTab === "nfts" && (
        <div className="nft-holdings mt-4">
          {loadingNFTs ? (
            <div className="spinner">Loading NFT Holdings...</div>
          ) : (
            <Stas789NFTs
              style={{ width: '100%' }}
              profile={profile}  // pass visitor wallet as object with address
              wallet={wallet}
              onNFTDataChange={(data) => {
                setNftHoldings(data.nfts);
              }}
            />
          )}
        </div>
      )}
      {activeTab === "comments" && (
        <div className="user-comments mt-4">
          {commentsLoading ? (
            <div className="spinner">Loading Comments...</div>
          ) : userComments.length > 0 ? (
            userComments.map((comment, idx) => (
              <div style={{marginBottom:'20px', border:'2px solid #ccc'}}><EmbeddedPost parentEmbed={true} wallet={wallet} meta={comment.reaction} />
              <EmbeddedPost isReply={true} wallet={wallet} meta={comment} post={comment.reaction} />
              </div>
            ))
          ) : (
            <div>No comments found.</div>
          )}
        </div>
      )}
      {showTipOverlay && (
        <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1050 }}>
          <div className="modal-backdrop fade show" style={{ zIndex: 1040 }}></div>
          <div className="modal fade show" style={{ display: 'block', zIndex: 1050 }} role="dialog">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Send Tip</h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => setShowTipOverlay(false)}
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body pt-0 pb-0">
                  <BSVTransferForm
                    wallet={wallet}
                    transfer_params={tranferParams}
                    onTransactionComplete={handleTransactionComplete}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Visitor;
