import React, { useState, useEffect } from 'react';
import { verifiedFetch } from '@helia/verified-fetch';
import CryptoJS from 'crypto-js';

const DecryptedImage = ({ cid, passphrase, alt }) => {
  const [dataUrl, setDataUrl] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    if (!cid || !passphrase) return;
    const url = cid.startsWith('ipfs://') ? cid : `ipfs://${cid}`;
    verifiedFetch(url)
      .then(async response => {
        if (!response.ok) throw new Error('Failed to fetch encrypted image');
        const encryptedContent = await response.text();
        const bytes = CryptoJS.AES.decrypt(encryptedContent, passphrase);
        const decryptedDataUrl = bytes.toString(CryptoJS.enc.Utf8);
        setDataUrl(decryptedDataUrl);
      })
      .catch(err => setError(err.message));
  }, [cid, passphrase]);

  if (error) return <div>Error: {error}</div>;
  if (!dataUrl) return <div className="card-text placeholder-glow p-3">  
  <h5 className="card-title placeholder-glow">
      <span className="placeholder col-6"></span>
  </h5>
  <span className="placeholder col-7"></span>
  <span className="placeholder col-4"></span>
  <span className="placeholder col-4"></span>
  <span className="placeholder col-6"></span>
  <span className="placeholder col-8"></span>
  </div>;

  return <img src={dataUrl} alt={alt || 'Decrypted Image'} style={{ maxWidth: '100%', display: 'block', margin: '0 auto' }} />;
};

export default DecryptedImage;
