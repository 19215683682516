import React from 'react';
import Notifications from './Notifications';
import 'bootstrap/dist/css/bootstrap.min.css';

const NotificationsModal = ({ wallet, onClose }) => {
  return (
    <div className="modal show d-block" tabIndex="-1" role="dialog" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Latest Notifications</h5>
            <button type="button" className="btn-close" aria-label="Close" onClick={onClose}></button>
          </div>
          <div className="modal-body" style={{ maxHeight: '400px', overflowY: 'auto' }}>
            <Notifications wallet={wallet} onNotificationClick={onClose} />
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default NotificationsModal;
