import React, { useState, useEffect } from 'react';
import { getNotifications } from '../services/apiOutCalls';
import { useNavigate } from 'react-router-dom';
import {convertCamelToCamelCase, toCamelCase} from '../utils/stringUtils'

const Notifications = ({ wallet, onNotificationClick }) => {
  const [updates, setUpdates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const capitalize = str => str ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase() : '';

  useEffect(() => {
    const fetchUpdates = async () => {
      setLoading(true);
      try {
        // For example: fetch notifications for past 7 days.
        const params = { days: 30 };
        const response = await getNotifications(wallet, params);
        if(response.success) {
          setUpdates(response.updates);
        } else {
          setError(response.error);
        }
      } catch (err) {
        setError(err.message);
      }
      setLoading(false);
    };
    if(wallet) fetchUpdates();
  }, [wallet]);

  if(loading) return <div>Loading notifications...</div>;
  if(error) return <div>Error: {error}</div>;
  if(!wallet) return <div>No notifications found. You need to create an account first and get some activity.</div>;
  if(updates.length === 0) return <div>No notifications found.</div>;

  return (
    <div className="notifications-container">
      {updates.map((item, idx) => {
        let actionText = '';
        if(item.type === 'replyTo') {
          actionText = 'added a ';
        } else if(item.type === 'repostOf') {
          actionText = 'did a ';
        } else {
          actionText = 'reacted with a ';
        }
        // Choose to display post name if available, otherwise use a truncated description.
        const postTitle = item.name ? item.name : (item.description ? item.description.substring(0, 100) + '...' : 'your post');
        return (
          <div
            key={idx}
            className="notification-item"
            style={{ cursor: 'pointer', padding: '10px', borderBottom: '1px solid #eee' }}
            onClick={() => {
              if(onNotificationClick) onNotificationClick();
              navigate(`/post/${item.post}`);
            }}
          >
              <small>{new Date(item.createdAt).toLocaleDateString()}</small>
              <p className='mb-1'>
              <span style={{ fontWeight: 'bold' }}>{item.count} {item.count > 1 ? 'people' : 'person'}</span> {actionText} {capitalize(convertCamelToCamelCase(item.type))} your post <br></br> <span style={{ fontStyle: 'italic' }}>{postTitle}</span>
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default Notifications;
