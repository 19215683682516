import React, { useState, useEffect } from 'react';
import '../css/IconButtons.css';
import { createReaction, getReactionCount } from '../services/apiOutCalls';
import { useNavigate, NavLink } from 'react-router-dom'; // import useNavigate and NavLink

// Define emoticons for each reaction type.
const reactionIcons = {
  like: "👍",
  laugh: "😂",
  love: "❤️",
  sad: "😢",
  dislike: "👎"
};

const IconButtons = ({ 
  ownProfile,
  reactionMode, 
  onCommentClick, 
  onExpandFullPost,
  onShareClick, 
  onTipClick,
  onToggleReaction,
  wallet,     // required for signing the reaction
  post        // full post object for accessing contractTx
}) => {
  const [reactionCounts, setReactionCounts] = useState(null);
  const navigate = useNavigate();

  // When reactionMode is triggered first time and reactionCounts not set, fetch counts.
  useEffect(() => {
    if (reactionMode && post && post.contractTx && reactionCounts === null) {
      getReactionCount(wallet,post.contractTx)
        .then(counts => setReactionCounts(counts))
        .catch(err => {
          console.error("Error fetching reaction counts:", err);
          setReactionCounts({});
        });
    }
  }, [reactionMode, post, reactionCounts, wallet.privateKey]);

  // Handle an emotion click: call createReaction using post.contractTx.
  const handleSelectReaction = async (reactionType) => {
    try {
      const outcome = await createReaction(wallet, { 
        type: reactionType,
        contractTx: post.contractTx
      });
      setReactionCounts(outcome && outcome.totals ? outcome.totals : {});
    } catch (error) {
      console.error("Error creating reaction:", error);
      setReactionCounts({});
    }
  };

  if (reactionMode) {
    return (
      <div className="icon-buttons reaction-mode">
        <button className="back-btn" onClick={onToggleReaction}>
          <i className="fa fa-arrow-left"></i>
        </button>
        {reactionCounts === null ? (
          <div className="loader">
            <i className="fa fa-spinner fa-spin"></i>
          </div>
        ) : (
          Object.entries(reactionIcons).map(([type, icon]) => (
            <button 
              key={type} 
              className="reaction-btn" 
              onClick={() => handleSelectReaction(type)}
            >
              <span>{icon}</span>
              <span className="reaction-count">{reactionCounts[type] || 0}</span>
            </button>
          ))
        )}
      </div>
    );
  }

  return (
    <div className="icon-buttons">
      <button onClick={onCommentClick} className="share-btn">
        <i className="fa fa-share"></i>
      </button>
      <button onClick={onShareClick} className="share-btn">
        <i className="fa fa-retweet"></i>
      </button>
      {onTipClick && !ownProfile && post?.recentOwner!==undefined && (
        <div className="tip-buttons">
          <button onClick={() => onTipClick(0.03)} className="tip-btn">$0.03</button>
        </div>
      )}
      <button className="reaction-toggle-btn" onClick={onToggleReaction}>
        {!ownProfile ? (
          <i className="fa fa-star-half-o" style={{ color: '#de8920' }}></i>
        ) : (
          <i className="fa fa-bar-chart"></i>
        )}
      </button>
      
    </div>
  );
};

export default IconButtons;